body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

button, input {
  outline: none;
}
input:not([type="radio"]):not([type="checkbox"]) {
  -webkit-appearance: none !important;
  -webkit-border-radius: 0px;
  padding-left: 0;
  padding-right: 0;
}
button {
  cursor: pointer;
}
