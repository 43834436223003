.App {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.App-logo {
  float: left;
  animation: App-logo-spin infinite 20s linear;
  height: 40px;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.App-header {
  background-color: #222;
  height: 40px;
  padding: 0px;
  color: white;
}

.App-header h2 {
  margin: 0;
  line-height: 32px;
  font-size: 24px;
  padding: 4px;
  font-weight: 100;
  color: #96bbce;
  letter-spacing: 1.2px;
}

.App-header input.project-name {
  background: none;
  width: 450px;
  font-size: 24px;
  line-height: 30px;
  padding: 0;
  margin: 0;
  color: #fff;
  border: none;
  border-bottom: 1px solid #96bbce;
}

.App-header .menu-right {
  display: inline-block;
  float: right;
}
._react-file-reader-input {
  display: inline-block;
}

.App-header button {
  background: #111;
  border: none;
  border-left: 1px solid #2e4550;
  font-size: 18px;
  font-weight: 200;
  line-height: 32px;
  padding: 4px 14px;
  color: #96bbce;
}
.App-header button:hover {
  background: #445;
  color: #a6e1ff;
}

.App-intro {
  font-size: large;
}

.graph {
  position: absolute;
  overflow: scroll;
  background: #e8e8e8;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 450px;
  padding: 10px;
}

.responsive-svg {
  height: 0;
  overflow: visible;
  position: relative;
}
.responsive-svg svg {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.graph > .responsive-svg {
  max-width: 600px;
  margin: 0 auto;
}
.list {
  position: absolute;
  overflow: scroll;
  top: 40px;
  bottom: 0;
  right: 0;
  width: 450px;
  background: #223b46;
  color: #96bbce;
  text-align: left;
  min-height: 500px;
}
.feature {
  padding: 10px;
  line-height: 24px;
  border-bottom: 3px solid #507c92;
}
.feature-key {
  color: #a6e1ff;
  font-weight: bold;
}
.feature input {
  font-size: 16px;
  background: none;
  color: #fff;
  border: none;
  border-bottom: 1px solid #96bbce;
}
.feature input.feature-name {
  width: 375px;
  font-weight: 300;
}
.feature input.feature-dependencies {
  width: 125px;
}
.feature .button-delete {
  background: #507c92;
  color: #ad002d;
  border: none;
  float: right;
}
.feature .button-delete:hover {
  background: #2e4550;
}
.list > button {
  display: block;
  width: 100%;
  background: #507c92;
  color: #fff;
  border: none;
  padding: 10px;
  text-align: left;
}
.list > button:hover {
  background: #2e4550;
}
.schedule-lines line {
  stroke: #ccc;
}
.dependency-lines line {
  stroke: #507c92;
  stroke-dasharray: 1, 4;
}
line.axis {
  stroke: #223b46;
}
.axis-label-symbol {
  fill: #223b46;
}
text.feature {
  fill: #0187cc;
  font-weight: bold;
  stroke: #fff;
  paint-order: stroke;
  stroke-width: 2px;
  text-anchor: middle;
  alignment-baseline: central;
}
